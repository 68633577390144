import React, { PropsWithChildren, useState } from 'react';
import { RiCloseLargeLine as CloseIcon } from 'react-icons/ri';
import { TfiAlignJustify as NavIcon } from 'react-icons/tfi';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Menu, useIndexQuery, useMenusQuery } from '@/api';
import { SecondaryButton } from '@/components/button';
import LanguageSelector from '@/components/languageSelector';
import Link from '@/components/link';
import Sidebar from '@/components/sidebar';

import { navList } from './config';

const NavButton = ({
  href,
  currentPath,
  className,
  children,
  target = '_self',
}: PropsWithChildren<{
  target: string | null;
  href: string;
  currentPath: string;
  className?: string;
}>) => {
  const isActive = currentPath === href;

  return (
    <Link
      href={href}
      className={clsx(
        className,
        'title-16r cursor-pointer select-none',
        isActive ? 'text-pri-100' : 'text-bk-100'
      )}
      target={target ? '_blank' : '_self'}
    >
      {children}
    </Link>
  );
};

const Header = () => {
  const { pathname } = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { data: indexData, isLoading: indexLoading } = useIndexQuery();
  const { data: menusData, isLoading: menusLoading } = useMenusQuery();

  const handleContactClick = () => {
    if (!indexData) return;

    window.open(indexData.contactUrl, '_blank');
  };

  const handleSidebarShow = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarHide = () => {
    setIsSidebarOpen(false);
  };

  const getNavButtons = () => {
    return navList.map((nav) => (
      <NavButton
        key={nav.name}
        href={nav.href}
        currentPath={pathname}
        target={nav.target}
      >
        {nav.name}
      </NavButton>
    ));
  };

  return (
    <div>
      <div className='relative border-b border-border-base'>
        <div className='lg:h-content container flex h-[80px] flex-row items-center justify-between lg:py-4'>
          <div className='flex flex-row items-center gap-x-12'>
            {/* logo */}
            <Link
              className='relative h-[28px] w-[105px] lg:h-[56px] lg:w-[213px]'
              href='/'
              target='_self'
            >
              <Image
                src='/image/header/logo.png'
                alt='logo'
                fill
                //width={213}
                //height={56}
              />
            </Link>
            {/* nav */}
            <div
              className='hidden flex-row items-center gap-x-8 md:flex'
              role='navigation'
            >
              {getNavButtons()}
            </div>
          </div>
          <div className='hidden flex-row items-center gap-x-8 lg:flex'>
            <SecondaryButton onClick={handleContactClick} className='px-8 py-3'>
              聯絡我們
            </SecondaryButton>
            <LanguageSelector />
          </div>
          <div className='flex md:hidden'>
            <button onClick={handleSidebarShow}>
              {isSidebarOpen ? <CloseIcon /> : <NavIcon />}
            </button>
          </div>
        </div>
      </div>
      <Sidebar isShow={isSidebarOpen}>{getNavButtons()}</Sidebar>
    </div>
  );
};

export default Header;
