const navList = [
  {
    name: '出版服務',
    href: '/',
    target: null,
  },
  {
    name: '出版流程',
    href: '/flow',
    target: null,
  },
  {
    name: '經驗分享',
    href: '/share',
    target: null,
  },
  {
    name: '獨立出版著作',
    href: 'https://www.scbooks.com.tw/#/search/series/SC8A/1',
    target: '_blank',
  },
  {
    name: '經銷通路',
    href: '/channel',
    target: null,
  },
];

export { navList };
