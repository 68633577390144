import { HTMLProps } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import languageDetector from '@/language-detector';

interface IProps extends HTMLProps<HTMLAnchorElement> {
  locale?: string;
}

const LanguageSwitchLink = ({ locale, ...rest }: IProps) => {
  const router = useRouter();
  const searchParams = new URLSearchParams(window.location.search);

  let href = rest.href || router.asPath;
  let pName = router.pathname;

  Object.keys(router.query).forEach((k) => {
    if (k === 'locale' && locale) {
      pName = pName.replace(`[${k}]`, locale);
      return;
    }
    pName = pName.replace(`[${k}]`, router.query[k] as string);
  });

  if (locale) {
    href = rest.href
      ? `/${locale}${rest.href}`
      : `${pName}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
  }

  return (
    <Link href={href} legacyBehavior>
      <a
        {...rest}
        onClick={(e) => {
          console.log('click');
          locale && languageDetector.cache?.(locale);
        }}
      >
        {locale}
      </a>
    </Link>
  );
};

export default LanguageSwitchLink;
