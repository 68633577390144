import React, { PropsWithChildren } from 'react';
import { RiCloseLargeLine as CloseIcon } from 'react-icons/ri';
import clsx from 'clsx';

import { Menu, useIndexQuery, useMenusQuery } from '@/api';
import { SecondaryButton } from '@/components/button';
import LanguageSelector from '@/components/languageSelector';

interface ISidebarProps {
  isShow: boolean;
}

const Sidebar = ({ isShow, children }: PropsWithChildren<ISidebarProps>) => {
  const { data: indexData, isLoading: indexLoading } = useIndexQuery();
  const handleContactClick = () => {
    if (!indexData) return;

    window.open(indexData.contactUrl, '_blank');
  };

  return (
    <div
      className={clsx(
        'fixed left-0 right-0 top-[150px] z-20 h-[calc(100%-150px)] border-t border-border-base bg-white p-5 shadow-lg duration-300',
        isShow ? 'translate-x-0' : 'translate-x-full'
      )}
    >
      <div className='flex flex-col gap-2 overflow-y-auto py-5'>{children}</div>
      <div className='absolute bottom-6 left-4 right-4 flex flex-col gap-6'>
        <SecondaryButton
          onClick={handleContactClick}
          className='px-8 py-3 text-center'
        >
          聯絡我們
        </SecondaryButton>
        {/* <LanguageSelector /> */}
      </div>
    </div>
  );
};

export default Sidebar;
